app.controller('InstantQuoteController', [
                                            '$scope',
                                            'Config',
                                            'CacheFactory',
                                            'quote',
                                            '$window',
                                            '$location',
                                            'setting',
                                            'helper',
function($scope,Config,CacheFactory,quote,$window,$location,setting,helper){

  	$scope.base_url = Config.base_url;
    $scope.detailsActive = angular.isDefined(CacheFactory.get('appCache').get('instantQuoteDetailsActive')) ? CacheFactory.get('appCache').get('instantQuoteDetailsActive')  :true;

  	$scope.details = {
  	    name:(CacheFactory.get('appCache').get('quote')) ? CacheFactory.get('appCache').get('quote').name  : '',
  	    telephone:(CacheFactory.get('appCache').get('quote')) ? CacheFactory.get('appCache').get('quote').telephone  : '',
  	    company:(CacheFactory.get('appCache').get('quote')) ? CacheFactory.get('appCache').get('quote').company  : '',
  	    email:(CacheFactory.get('appCache').get('quote')) ? CacheFactory.get('appCache').get('quote').email  : '',
  	    deliveryPostcode:(CacheFactory.get('appCache').get('quote')) ? CacheFactory.get('appCache').get('quote').deliveryPostcode  : '',
  		  collectionPostcode:(CacheFactory.get('appCache').get('quote')) ? CacheFactory.get('appCache').get('quote').collectionPostcode  : '',
  		  deliveryDate: (CacheFactory.get('appCache').get('deliveryDate')) ?  moment(CacheFactory.get('appCache').get('deliveryDate')).toDate() : moment().add(1,'days').toDate(),
  		  collectionDate: (CacheFactory.get('appCache').get('collectionDate')) ? moment(CacheFactory.get('appCache').get('collectionDate')).toDate() : moment().add(8,'days').toDate(),
  	    deliveryDateOpened:false,
  	    collectionDateOpened:false,
  	    hireDays:(CacheFactory.get('appCache').get('hireDays')) ? CacheFactory.get('appCache').get('hireDays')  : 8,
  	    hireWeeks:(CacheFactory.get('appCache').get('hireWeeks')) ? CacheFactory.get('appCache').get('hireWeeks')  : 1,
  	    deliveryDateOptions:{
  	      minDate:moment().add(1,'days').toDate(),
  	      startingDay:1,
  	      showWeeks:false
  	    },
  	    collectionDateOptions:{
  	      minDate:moment().add(2,'days').toDate(),
  	      startingDay:1,
  	      showWeeks:false
  	    }
  	}

    $scope.init = function(){
      //get brand setting
      setting.get().then(function(response) {
            //set min delivery and collection dates based on saved cut-off time
            if(moment.utc().utcOffset(60).hour()>=parseInt(response.data.global.cutoff_time)){
                $scope.details.deliveryDateOptions.minDate = moment().add(parseInt(response.data.global.min_date_offset_after),'days').toDate();
                $scope.details.collectionDateOptions.minDate = moment().add(parseInt(response.data.global.min_date_offset_after)+1,'days').toDate();
            }else{
                $scope.details.deliveryDateOptions.minDate = moment().add(parseInt(response.data.global.min_date_offset_before),'days').toDate();
                $scope.details.collectionDateOptions.minDate = moment().add(parseInt(response.data.global.min_date_offset_before)+1,'days').toDate();
            }
        }, function(response) {
         
        });
    }

    $scope.copyPostcode = function(event){
      $scope.details.collectionPostcode = $scope.details.deliveryPostcode;
    }

    $scope.setCol = function(){
        $scope.details.collectionDate = new Date($scope.details.deliveryDate.getFullYear(), $scope.details.deliveryDate.getMonth(), $scope.details.deliveryDate.getDate());
        $scope.details.collectionDate = moment($scope.details.collectionDate).add(1,'days').toDate();
        $scope.details.collectionDateOptions.minDate = $scope.details.collectionDate;
        $scope.details.collectionDateOpened = true;
        $scope.setHirePeriod();
    }
    
    $scope.setHirePeriod = function(){
        
        var timeDiff = Math.abs($scope.details.collectionDate.getTime() - $scope.details.deliveryDate.getTime());
        $scope.details.hireDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        if($scope.details.hireDays==1){
          $scope.details.hireWeeks = 1;
        }else{
          $scope.details.hireWeeks = Math.round(($scope.details.hireDays/7)+0.35);
        }
        CacheFactory.get('appCache').put('hireDays',$scope.details.hireDays);
        CacheFactory.get('appCache').put('hireWeeks',$scope.details.hireWeeks);
        CacheFactory.get('appCache').put('deliveryDate',moment($scope.details.deliveryDate).utc());
        CacheFactory.get('appCache').put('collectionDate',moment($scope.details.collectionDate).utc());
    }

    $scope.open = function(field) {
        if(field=='col'){
          $scope.details.collectionDateOpened = true;
        }else{
          $scope.details.deliveryDateOpened = true;
        }
    }

    $scope.getQuote = function(form){

      if(form.$valid){

        helper.showLoader();

        data = {
          'brand':Config.brand_key,
          'delivery-postcode':$scope.details.deliveryPostcode.toUpperCase(),
          'collection-postcode':$scope.details.collectionPostcode.toUpperCase(),
          'company':$scope.details.company,
          'name':$scope.details.name,
          'email':$scope.details.email,
          'telephone':$scope.details.telephone,
          'hire-days':$scope.details.hireDays,
          'user-agent':angular.element('#user-agent').val(),
          'user-ip':angular.element('#ip').val(),
          'token':angular.element('#user_token').val(),
          'delivery-date-timestamp': moment($scope.details.deliveryDate).endOf('day').unix(),
          'collection-date-timestamp': moment($scope.details.collectionDate).endOf('day').unix()
        };

        //get the delivery and collection timestamps
        quote.saveInstantQuote(data).then(function(response) {
  	        data = response.data;
            CacheFactory.get('appCache').put('quote',data);
            CacheFactory.get('appCache').put('instantQuote',true);
  	        CacheFactory.get('appCache').put('instantQuoteDetailsActive',false);
            helper.hideLoader();
  	        //$window.location.href = $scope.base_url+'ourproducts';

            //hide instant quote section and show products
            $scope.detailsActive = false;
            if($('[class*="isotope_container"]').length){
              setTimeout(function(){
                $('[class*="isotope_container"]').isotope('layout');
              },10);
            }

        }, function(response) {
          	console.log(response);
        });
      }
    }

    $scope.editInstantQuote = function(){
        helper.showLoader();  
        setTimeout(function(){
          helper.hideLoader();
          $scope.detailsActive = true;
          CacheFactory.get('appCache').put('instantQuoteDetailsActive',true);
          $scope.$apply();
        },200);
    }

}]);
