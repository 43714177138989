app.factory('quote', ['$http','Config','$rootScope','CacheFactory', function($http,Config,$rootScope,CacheFactory) {

	var factoryFns =  {

	  isServiceMandatory: function(data){
	  	return $http({
		  method: 'POST',
		  data:{cart:data},
		  url: Config.api_url+'quote/check-mandatory-service',
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },
	  get: function(data) {
	  	return $http({
		  method: 'POST',
		  data:data,
		  url: Config.api_url+'quote',
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },
	  validateApplyDiscount:function(data){
	  	return $http({
		  method: 'POST',
		  data:data,
		  url: Config.api_url+'quote/validate-apply-promo',
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },
	  saveSession: function(data){
	  	return $http({
		  method: 'POST',
		  data:{quote:data},
		  url: Config.base_url+'checkout/save-quote-session',
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },
	  getCountryList:function(){
	  	return $http({
		  method: 'GET',
		  url: Config.api_url+'doris/countries',
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },
	  getSavedAddresses:function(customerID){
	  	return $http({
		  method: 'GET',
		  url: Config.api_url+'doris/addresses?customer-id='+customerID,
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },getCraftyResults:function(postcode){
	  	return $http({
		  method: 'POST',
		  data:{
		  		key:Config.craftyclicks_api_key,
		  		query:postcode,
		  		country:'GB'
		  },
		  url: 'https://api.craftyclicks.co.uk/address/1.1/find',
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },sendStockCheckEmail: function(cart, quote){
		return $http({
			method: 'POST',
			url: Config.base_url+'checkout/handle-missing-stock',
			data: {
				items: cart,
				quote: quote,
			},
			headers:{
				'authorization':Config.api_key
			}
		  });
	  },getProductAlternatives: function(product, brand){
		return $http({
			method: 'POST',
			data:{
				product: product,
				brand: brand,
			},
			url: Config.api_url+'product/get-product-alternatives',
			headers:{
				'authorization':Config.api_key
			}
		  });
	  },priceBreaksCheck: function(product, brand, qty, index){
		  product['qty'] = qty;
		return $http({
			method: 'POST',
			data:{
				product: product,
				brand: brand,
				index: index,
			},
			url: Config.api_url+'product/price-breaks',
			headers:{
				'authorization':Config.api_key
			}
		  });
	  },selectCraftyAddress:function(id){
	  	return $http({
		  method: 'POST',
		  data:{
		  		key:Config.craftyclicks_api_key,
		  		id:id,
		  		country:'GB'
		  },
		  url: 'https://api.craftyclicks.co.uk/address/1.1/retrieve',
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },sendCustomerQuote: function(data){
	  	query ='?web-quote-id='+data.webQuoteID+'&email='+data.email;
	  	return $http({
		  method: 'GET',
		  url: Config.api_url+'quote/send-customer-quote'+query,
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  },getModifiers: function(data){
	  	var query = '?brand='+Config.brand_key+'&delivery-postcode='+data.deliveryPostcode+'&collection-postcode='+data.collectionPostcode+'&cart-total-weight='+data.cartTotalWeight;
	  	query +='&delivery-date-timestamp='+data.delTimestamp+'&collection-date-timestamp='+data.colTimestamp;
	  	return $http({
		  method: 'GET',
		  url: Config.api_url+'quote/transport'+query,
		  headers:{
		  	'authorization':Config.api_key
		  }
		});
	  }, saveInstantQuote: function(data){
		  	return $http({
			  method: 'POST',
			  data:data,
			  url: Config.api_url+'instant-quote',
			  headers:{
			  	'authorization':Config.api_key
			  }
			});
	  }, retrieveQuote: function(quoteID,token){
	  		var query= '?quote-id='+quoteID;
	  		if(token){
	  			query += '&token='+token;
	  		}
	  		return $http({
			  method: 'GET',
			  url: Config.base_url+'checkout/retrieve-quote'+query,
			  headers:{
			  	'authorization':Config.api_key
			  }
			});
	  }, updateTlifStatus: function(data){
		return $http({
			method: 'POST',
			data:data,
			url: Config.api_url+'quote/update-tlif-status',
			headers:{
				'authorization':Config.api_key
			}
		  });
	  }, updateUtmTerm: function(term,quoteID){
	  	var query = '?quote-id='+quoteID+'&utm-term='+term;
	  	$http({
			  method: 'GET',
			  url: Config.api_url+'remarketing-emails-track'+query,
			  headers:{
			  	'authorization':Config.api_key
			  }
			}).then(function(response) {
            	console.log('success');
	        }, function(response) {
	            console.log(response);
	        });
	  },
	  getInstallDerig: function(data) {
		query = '?brand='+data.brand+
				'&delivery-postcode='+data.deliveryPostcode+
				'&collection-postcode='+data.collectionPostcode+
				'&cart-total-weight='+data.cartTotalWeight;

		return $http({
		method: 'GET',
		url: Config.api_url+'quote/transport'+query,
		headers:{
			'authorization':Config.api_key
		}
	  });
	},
	getSurcharges: function(data){
		query = '?brand='+data.brand+
				'&delivery-postcode='+data.deliveryPostcode+
				'&collection-postcode='+data.collectionPostcode;
		return $http({
		method: 'GET',
		url: Config.api_url+'surcharges'+query,
		headers:{
			'authorization':Config.api_key
		}
	  });
	}
	}

	return factoryFns;

}]);